import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import CardEvent from "../../v2023/UI/Card/card-event";
import CardPost from "../../v2023/UI/Card/card-post";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { WhiteTileOpinion } from "../tile/tile";

export const CarouselTouch = ({ slides, lang, showArrows = false, showStatus = false, showThumbs = false, showIndicators = false, useCardPost = false }) => {
    slides.map(slide => {
        if (!slide.message) {
            throw new Error('Falta el prop "message" dentro de cada slide')
        }
    })

    const [carouselIndicator, setCarouselIndicator] = useState(0)

    const nextCarousel = () => {
        if (carouselIndicator === (slides.length - 1)) {
            setCarouselIndicator(0)
            return
        }

        setCarouselIndicator(carouselIndicator + 1)
    }

    const prevCarousel = () => {
        if (carouselIndicator === 0) {
            setCarouselIndicator(slides.length - 1)
            return
        }

        setCarouselIndicator(carouselIndicator - 1)
    }

    return (
        <div className="carrousel carrousel--touch">
            <div className="carrousel__content">
                {showArrows &&
                    <div className="carrousel__content__button carrousel__content__button--prev">
                        <button className="button-icon button-icon--secondary button-icon--lg" onClick={prevCarousel}>
                            <i className="icon-arrow-left"></i>
                        </button>
                    </div>
                }

                <div className="carrousel__content__box">
                    <Carousel
                        showArrows={showArrows}
                        showStatus={showStatus}
                        showThumbs={showStatus}
                        showIndicators={showIndicators}
                        selectedItem={carouselIndicator}
                        onChange={(index) => setCarouselIndicator(index)}
                    >
                        {slides.map(slide => {
                            return (
                                useCardPost ?
                                    <CardPost description={slide.message} image={slide.image} /> :
                                    <WhiteTileOpinion
                                        opinion={slide.message}
                                        logo={slide.image}
                                        url={slide.url}
                                        urlText={slide.knowMore}
                                    />
                            )
                        })}
                    </Carousel >
                </div>

                {showArrows &&
                    <div className="carrousel__content__button  carrousel__content__button--next">
                        <button className="button-icon button-icon--secondary button-icon--lg" onClick={nextCarousel}>
                            <i className="icon-arrow-right"></i>
                        </button>
                    </div>
                }
            </div>

            <div className="carrousel__dots">
                {slides.map((item, index) => {
                    return <a className={`dots__dot ${carouselIndicator === index ? 'dots__dot--active' : ''}`} key={index} onClick={() => setCarouselIndicator(index)} />
                })}
            </div>
        </div>
    )
}

// export default CarouselTouch