import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useRef, useState } from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import MainSection100 from "../components/Layout/main-section-100"
import MainSection50 from "../components/Layout/main-section-50"
import NewLayout from "../components/new_layout"
import Seo from "../components/seo"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import CardPost from "../components/v2023/UI/Card/card-post"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import {CarouselTouch} from "../components/v2024/Carousel/carousel-touch"
import { Dropdown } from "../components/v2024/Dropdown/dropdown"
import { MainSection7030 } from "../components/Layout/main-section-70-30"

const CaseStudyPage = ({ data, pageContext }) => {
    const page = data.page
    const tagCategories = data.tagCategory.edges
    const tags = data.tags.edges
    const successCases = data.successCases.edges
    const lang = pageContext.langKey

    const showOpinions = page.carousel_case_studies
    const allOpinions = data.allOpinions.edges

    // Estado para el caso de éxito que se está mostrando
    const [activeCaseStudy, setActiveCaseStudy] = useState(
        page.carousel_customers[0]
    )

    // Función para recoger el id numérico del id compuesto por strapi
    const getNumericId = (fullId) => {
        return parseInt(fullId?.split('_')[1])
    }

    // Reestructurar showOpinion para el carousel
    // logo | message | url
    showOpinions.forEach(showOpinion => {
        const opinion = allOpinions.find(item => getNumericId(item.node.id) === showOpinion.opinion)
        showOpinion.message = opinion.node.message
        showOpinion.image = opinion.node.company_logo_2
        showOpinion.knowMore = page.text_saber_mas_carousel_opinions
        // const test = allOpinions.find(item => getNumericId(item.node.id) === showOpinion.opinion)
    })
    const successCasesShow = () => {
        // Buscamos los casos de éxito que coincidan con el tag
        let aux = successCases.filter(item => getSuccessCaseByTag(item))
        // Invertimos el orden de los casos de éxito ya que debe mostrarse los nuevos primero
        aux.reverse()
        return aux
    }

    // Filtros por defecto
    const defaultFilters = {}
    tagCategories.map(tagCategory => {
        const categoryId = getNumericId(tagCategory.node.id)
        defaultFilters[categoryId] = null
    })

    // Creamos los filtros con los valores por defecto
    const [filters, setFilters] = useState(defaultFilters)

    const filtersRefs = useRef([])
    // Restablecer los filtros
    const resetFiltersSuccessCases = () => {
        setFilters(defaultFilters)
        filtersRefs.current?.forEach((ref) => {
            if (ref) {
                ref.restartSelected()
            }
        });

    }

    // Función al seleccionar un valor de un dropdown
    const dropdownFilter = (data) => {
        const categoryTagId = data.node.case_study_tag_category.id
        // const tagId = getNumericId(data.node.id)
        const tag = data
        setFilters(prevState => ({
            ...prevState,
            [categoryTagId]: tag
        }))
    }

    // Validamos si hay filtros puestos
    const areFiltersSet = () => {
        let filtersSet = false
        Object.values(filters).map(filter => {
            if (filter !== null) {
                filtersSet = true
            }
        })
        return filtersSet
    }

    // Función para recoger la categoría a la que pertenece una etiqueta
    const getTagCategories = (tag) => {
        const filteredTag = tags.find(item => getNumericId(item.node.id) === tag)
        return filteredTag.node.case_study_tag_category
    }

    // Recoger los casos de éxito en base a los filtros
    const getSuccessCaseByTag = (item) => {
        if (!areFiltersSet()) {
            return true
        }

        let exist = true
        item.node.case_study_tags.find(caseTag => {
            const caseTagCategory = getTagCategories(caseTag.id).id
            Object.entries(filters).forEach(([tagCategoryId, filter]) => {
                if (filter !== null && caseTagCategory === parseInt(tagCategoryId) && getNumericId(filter?.node.id) !== caseTag.id) {
                    exist = false
                }
            })
        });
        return exist
    }

    return (
        <NewLayout
            pageContext={pageContext}
            lang={pageContext?.langKey}
            translates={pageContext?.translates}
        >
            <Seo
                lang={lang}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo.image_2.localFile.publicURL}
            />
            <main className="main">
                <MainSection50
                    sectionType="white"
                    sectionColor="green-sm"
                    sectionSize="lg"
                    childrenLeft={
                        <>
                            <h1 h1 className="merriweather">{page.title}</h1>
                            <div className="container__text">
                                <ReactMarkdown
                                    children={page.description}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                            <div className="grid-xs-4">
                                {page.carousel_customers.map((slide, index) => {
                                    return (
                                        <div onClick={() => setActiveCaseStudy(slide)} className={slide.case_study.id !== activeCaseStudy.case_study.id ? "desaturate" : ""}>
                                            <GatsbyImage
                                                alt={slide.case_study.title}
                                                image={getImage(slide.case_study.logo_new.localFile)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <LinkButton button={page.button} className="button button--xl" />
                        </>
                    }
                    childrenRight={
                        <>
                            <CardPost
                                name={activeCaseStudy.case_study.title}
                                image={activeCaseStudy.image}
                                url={activeCaseStudy.case_study.url}
                                showMore
                                lang={lang}
                            />
                        </>
                    }
                />

                <MainSection100 sectionType="color" sectionColor="green">
                    {/* {showOpinions.map(opinion => {
                        return <WhiteTileOpinion logo={opinion.opinion.node.company_logo_simple} opinion={opinion.opinion.node.message} url={opinion.url} />
                    })} */}
                    <CarouselTouch
                        slides={showOpinions}
                        lang={lang}
                        showArrows
                    />
                </MainSection100>

                <MainSection7030 
                    children30={
                        <div className="container__text">
                                <h2>{page.title_history}</h2>
                                <div className="dropdown-group">
                                    {tagCategories.map((tagCategory, index) => {
                                        const tagCategoryId = getNumericId(tagCategory.node.id)
                                        const options = tags.filter(tag =>
                                            tag.node.case_study_tag_category?.id == tagCategoryId
                                        )

                                        return (
                                            <Dropdown
                                                showValue={tagCategory.node.name}
                                                options={options}
                                                onSelectOption={dropdownFilter}
                                                ref={(el) => filtersRefs.current[index] = el}
                                            />
                                        )
                                    })}
                                    {areFiltersSet() &&
                                        <a className="link--small" onClick={() => resetFiltersSuccessCases()}>
                                            {page.filters_reset}
                                        </a>
                                    }
                                </div>
                            </div>
                    } 
                    children70={
                        <>
                            {
                                successCasesShow().length === 0 && (
                                    <div className="empty-state">
                                        <GatsbyImage alt={page.filters_no_found_image.alternativeText} image={getImage(page.filters_no_found_image.localFile)} />
                                        <ReactMarkdown
                                            children={page.filters_no_found_text}
                                            rehypePlugins={[rehypeRaw]}
                                        />
                                    </div>
                                )
                            }
                            {
                                successCasesShow().length !== 0 && (
                                    <div className="grid-lg-2">
                                        {
                                            successCasesShow().map(item => {
                                                return (
                                                    <CardPost
                                                        name={item.node.title}
                                                        image={item.node.logo_new}
                                                        url={item.node.url}
                                                        description={item.node.seo.meta_description}
                                                        // tag={item.node.tag}
                                                        tagHighlightSmall={item.node.tag}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </>
                    } 
                />

                {/* <MainSection6040
                    sectionType= "align-top"
                    sectionColor="green-sm"
                    children40={
                        <div className="container__text">
                            <h2>{page.title_history}</h2>
                            {tagCategories.map(tagCategory => {
                                const tagCategoryId = getNumericId(tagCategory.node.id)
                                const options = tags.filter(tag =>
                                    tag.node.case_study_tag_category?.id == tagCategoryId
                                )
                                const selected = filters[getNumericId(tagCategory.node.id)]?.node.name

                                return (
                                    <Dropdown
                                        showValue={selected ?? tagCategory.node.name}
                                        options={options}
                                        onSelectOption={dropdownFilter}
                                    />
                                )
                            })}
                            {areFiltersSet() &&
                                <a className="link--small" onClick={() => resetFiltersSuccessCases()}>
                                    {page.filters_reset}
                                </a>
                            }
                        </div>
                    }
                    children60={
                        <>
                            {
                                successCasesShow().length === 0 && (
                                    <ImageGroup basicInfoWithImage={{ description: page.filters_no_found_text, image: page.filters_no_found_image }} centered />
                                )
                            }
                            {   
                                successCasesShow().map(item => {
                                    return (
                                        <CardPost
                                            name={item.node.title}
                                            image={item.node.logo_new}
                                            url={item.node.url}
                                            description={item.node.seo.meta_description}
                                            // tag={item.node.tag}
                                            tagHighlightSmall={item.node.tag}
                                        />
                                    )
                                })
                            }
                        </>
                    }
                /> */}
                <ReadyToTalk lang={lang} />
            </main>
        </NewLayout>
    )
}

export default CaseStudyPage

export const caseStudyQuery = graphql`
    query ($langKey: String) {
        page: strapi2024CasosDeExito(locale: { eq: $langKey }) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            title
            description
            button {
                text
                url
                blank
            }
            carousel_customers {
                case_study {
                    id
                    title
                    url
                    logo_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 146
                                    quality: 100
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 690
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            opinions {
                name
                job_position
                message
                company_logo_simple {
                    name
                    alternativeText
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                width: 280,
                                placeholder: NONE, 
                                formats: [WEBP]
                            )
                        }
                    }
                }
                company_logo_2 {
                    name
                    alternativeText
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(width: 280, placeholder: NONE, formats: [WEBP])
                        }
                    }
                }
                photo {
                    name
                    alternativeText
                    localFile {
                        publicURL
                    }
                }
                locale
            }
            carousel_case_studies {
                url
                opinion 
            }
            title_history
            filters_no_found_text
            filters_no_found_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 128
                            quality: 100
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            filters_reset
            use_cases
            industry
            product
            text_saber_mas_carousel_opinions
        }
        tagCategory: allStrapiTagCategoryStudyCases(
            filter: { locale: { eq: $langKey } }
        ) {
            edges {
                node {
                    id
                    name
                }
            }
        }
        tags: allStrapiTagStudyCases(filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    id
                    name
                    case_study_tag_category {
                        id
                    }
                }
            }
        }
        successCases: allStrapiCaseStudy(filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    seo {
                        meta_description
                    }
                    title
                    url
                    tag
                    logo_new {
                        name
                        alternativeText
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                width: 910
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                                )
                            }
                        }
                    }
                    case_study_tags {
                        id
                    }
                }
            }
        }
        allOpinions: allStrapiOpinions(filter: { locale: {eq: $langKey } }) {
            edges {
                node {
                    id
                    message
                    company_logo_simple {
                        name
                        alternativeText
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(width: 280, placeholder: NONE, formats: [WEBP])
                            }
                        }
                    }
                    company_logo_2 {
                        name
                        alternativeText
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(width: 280, placeholder: NONE, formats: [WEBP])
                            }
                        }
                    }
                }
            }
        }
    }
`
