import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LinkButton from "../../v2023/UI/LinkButton/link-button";
import { Link } from "gatsby";

export const Tile = ({ title, body, button, image, purple, buttonAction }) => {
    let tileClass = 'tile'
    if (purple) {
        tileClass += ' tile--purple'
    }
    if (image) {
        tileClass += ' tile--image'
    }

    return (
        <div className={tileClass}>
            {title && (
                <h3>{title}</h3>
            )}
            {body && (
                <div className="tile__body">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={body} />
                </div>
            )}
            {button && buttonAction && (
                <button onClick={buttonAction} className="button button--sm">
                    {button.text}
                </button>
            )}
            {button && !buttonAction && (
                <LinkButton button={button} className="button button--sm" extras={<i className="icon-arrow-right"></i>} />
            )}
            {image && (
                <div className="tile__image">
                    <GatsbyImage
                        alt={image.alternativeText}
                        image={getImage(image.localFile)}
                        placeholder="blurred"
                        quality={100}
                    />
                </div>
            )}
        </div>
    )
}

export const WhiteTileOpinion = ({ logo, opinion, url, urlText, avatar }) => {
    return (
        <div className="tile tile--white--opinion">
            <div className="tile__body">
                {logo &&
                    <div className="tile__body__column">
                        <div className="column__image">
                            <GatsbyImage alt={logo.alternativeText} image={getImage(logo.localFile)} />
                        </div>
                    </div>
                }
                <div className="tile__body__column">
                    <ReactMarkdown children={`"${opinion}"`} rehypePlugins={[rehypeRaw]} />
                    {url &&
                        <Link class="link--big" to={url}>{urlText}<i class="icon-arrow-right"></i></Link>
                    }
                    {/* Si esto pasa a ser un componente, también podria tener el componente avatar aqui dentro en vez del link */}
                    {avatar &&
                        <div className="avatar">
                            <div className="avatar__job">
                                <b>name</b>
                                <span>job</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}